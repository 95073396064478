import React from 'react'
import { MatchRenderProps } from '@reach/router'
import { Col, Row } from 'antd'

import { LoginPicture } from '../../assets/login'
import { Logo } from '../../assets/logo'
import BackArrow from '../../components/BackArrow'

import Login from './log'

import styles from './styles.module.css'

const LoginPage: React.FC<MatchRenderProps<string>> = ({ navigate }) => (
  <Row justify="center" align="middle">
    <Col xs={24} lg={12} className={styles.innerLeftContainer}>
      <div className={styles.backArrow}>
        <BackArrow clickAction={() => navigate('/inicio')} />
      </div>

      <Row justify="center" align="middle" className={styles.fullHeight}>
        <Col xs={22} sm={12}>
          <Row justify="center">
            <Col xs={16} md={24} className={styles.centered}>
              <Logo className={styles.logo} />
            </Col>
          </Row>
          <Login />
        </Col>
      </Row>
    </Col>
    <Col xs={0} lg={12} className={styles.innerRightContainer}>
      <Row className={styles.fullHeight} align="middle">
        <Col xs={24}>
          <LoginPicture className={styles.loginPicture} />
          <p className={styles.welcomeTitleFont}>¡Bienvenido a Simplus!</p>
          <p className={styles.welcomeBodyFont}>
            La plataforma perfecta que facilita la gestión y compra de tus
            viajes con múltiples operadores como: Turbus, Cóndor, Jac y Bio Bio.
          </p>
        </Col>
      </Row>
    </Col>
  </Row>
)

export default LoginPage
